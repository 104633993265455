import svg_logo from "assets/svg/logo-shoe.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

export default function Menu() {
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <div className="flex justify-between items-center py-4">
        <Link to="/">
          <ReactSVG src={svg_logo} className="w-[126px]" />
        </Link>
        <div className="flex gap-4">
          <div className="flex gap-1 items-center">
            <button
              className={`${i18n.language === "en" ? "font-semibold text-yellow-400" : ""}`}
              onClick={() => i18n.changeLanguage("en")}
            >
              EN
            </button>
            <span className="separator text-gray-300">|</span>
            <button
              className={`${i18n.language === "fr" ? "font-semibold text-yellow-400" : ""}`}
              onClick={() => i18n.changeLanguage("fr")}
            >
              FR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
