import Menu from "features/Menu/Menu";
import routeConfig from "features/Routing/routeConfig";
import "firebase/firestore";
import translation_en from "i18n/en.json";
import translation_fr from "i18n/fr.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en: translation_en,
      fr: translation_fr,
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  return (
    <div id="mainLayout">
      <Router>
        <Menu />
        <Switch>
          {routeConfig.map((route) => (
            <Route {...route} key={route.path} />
          ))}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
