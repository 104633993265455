import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import StatusPage from "./StatusPage";
import { useTranslation } from "react-i18next";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function TokenExchange() {
  const query = useQuery();
  const code = query.get("code");
  const scope = query.get("scope");

  const { t, i18n } = useTranslation();

  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (!scope || !code) {
      setStatus({
        type: "error",
        title: t("register_error_missing_data_title"),
        cta: {
          link: "/",
          text: t("back_to_leaderboard"),
        },
      });
      return;
    }

    if (scope != "read,activity:read_all") {
      setStatus({
        type: "error",
        title: t("register_error_missing_permissions_title"),
        body: t("register_error_missing_permissions_body"),
        cta: {
          link: `http://www.strava.com/oauth/authorize?client_id=61504&response_type=code&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/exchange_token&approval_prompt=force&scope=activity:read_all`,
          text: t("connect_strava_account"),
        },
      });
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/strava/oauth_token`, {
        code: query.get("code"),
        scope: query.get("scope"),
      })
      .then((res) => {
        setStatus({
          type: "success",
          title: t("register_success_title"),
          body: t("register_success_body"),
          cta: {
            link: "/",
            text: t("back_to_leaderboard"),
          },
        });
      })
      .catch((err) => {
        setStatus({
          type: "error",
          title: t("register_error_strava_title"),
          body: t("register_error_strava_body"),
          cta: {
            link: "/",
            text: t("back_to_leaderboard"),
          },
        });
      });
  }, []);

  if (!status) return "Loading...";

  return <StatusPage status={status} />;
}
