import { getFirestore } from "firebase/firestore";
import { FirebaseAppProvider, FirestoreProvider, useFirebaseApp, useFirestore } from "reactfire";

const firebaseConfig = {
  apiKey: "AIzaSyCyZTndyV_KBVw7nhuZvFs_3XTNq8n2NcE",
  authDomain: "mf-life-strava.firebaseapp.com",
  projectId: "mf-life-strava",
  storageBucket: "mf-life-strava.appspot.com",
  messagingSenderId: "730009914162",
  appId: "1:730009914162:web:9a3ea6d45557e2f4a9e52b",
};

function FirebaseServices({ children }) {
  const app = useFirebaseApp();
  const firestore = getFirestore(app);
  // Example with other Firebase services
  // const storage = getStorage(firebase);
  // const auth = getAuth(firebase);

  return (
    // Example with other Firebase services
    // <FirestoreProvider sdk={firestore}>
    //   <StorageProvider sdk={storage}>
    //     <AuthProvider sdk={auth}>{children}</AuthProvider>
    //   </StorageProvider>
    // </FirestoreProvider>

    <FirestoreProvider sdk={firestore}>{children}</FirestoreProvider>
  );
}

export default function FirebaseProviders({ children }) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseServices>{children}</FirebaseServices>
    </FirebaseAppProvider>
  );
}
