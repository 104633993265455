import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isoWeek from "dayjs/plugin/isoWeek";
import { useTranslation } from "react-i18next";

dayjs.extend(isoWeek);
dayjs.extend(duration);

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <div className="flex flex-col lg:flex-row items-center my-8 lg:my-16 gap-8">
          <div className="flex flex-col gap-4 items-start">
            <h2 className="text-3xl sm:text-4xl font-extrabold tracking-tight text-gray-900">{t("cta_title")}</h2>
            <p className="text-xl sm:text-2xl">{t("cta_text")}</p>
            <a
              className="hover:opacity-70 w-[193px]"
              href={`http://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/exchange_token&approval_prompt=force&scope=activity:read_all`}
            >
              <img src="/strava.png" />
            </a>
          </div>
          <img src="/leaderboard.png" className="max-h-[500px] mx-auto bg-[#232529] p-4 lg:p-10 rounded-md" />
        </div>
      </div>
    </>
  );
}
