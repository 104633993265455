import React from "react";

import svg_error from "assets/svg/error.svg";
import svg_success from "assets/svg/success.svg";

export default function StatusError({ status }) {
  return (
    <div style={{ textAlign: "center", paddingTop: 30 }}>
      <img
        src={(status.type === "success" && svg_success) || (status.type === "error" && svg_error)}
        style={{ height: 300 }}
      />
      <h1>{status.title}</h1>
      <div>{status.body}</div>
      {status.cta && (
        <div>
          <a href={status.cta.link} className="button" style={{ marginTop: 25 }}>
            {status.cta.text}
          </a>
        </div>
      )}
    </div>
  );
}
