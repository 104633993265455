import Home from "Home";
import TokenExchange from "features/Registration/TokenExchange";

export default [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/exchange_token",
    component: TokenExchange,
  },
  {
    path: "/week/:weekId",
    component: Home,
  },
];
